$(function() {
    $('#LoginWrapper').modal().on('shown.bs.modal', function() {
        $('#login-form #inputEmail').focus();
    });

    $('#login-form').submit(function() {

        var data = $(this).serializeArray();
       
        $.ajax({
            url: '/json?module=user&method=login',
            type: "POST",
            dataType: "json",
            data: data,
            success: function(data){
                if (data.error) {
                   $('#loginerror').html(data.message);
                   $('#loginerror').parent().removeClass('hide');
                }else{
                    window.location = data.url;
                }

            },
            error: function(error){
                 console.log("Error:");
                 console.log(error);
            }
        });

        return false;
    });

    $('#lostpassword-form').submit(function() {

        console.log('YESSSS');

        var data = $(this).serializeArray();
        

        $.ajax({
            url: '/json?module=user&method=lostpassword',
            type: "POST",
            dataType: "json",
            data: data,
            success: function(data){
                if (data.error) {
                    $('#lostpassworderror').html(data.message);
                    $('#lostpassworderror').parent().removeClass('hide');
                }else{
                    $('#lostpassword-form').displayMessage(data);
                }

            },
            error: function(error){
                 console.log("Error:");
                 console.log(error);
            }
        });

        return false;
    });

    $('#changepassword-form').submit(function() {

        var data = $(this).serializeArray();
       

        $.ajax({
            url: '/json?module=user&method=changepassword',
            type: "POST",
            dataType: "json",
            data: data,
            success: function(data){
                if (data.error) {
                    $('#changepassworderror').html(data.message);
                    $('#changepassworderror').parent().removeClass('hide');
                }else{
                     window.location = data.url;
                }

            },
            error: function(error){
                 console.log("Error:");
                 console.log(error);
            }
        });

        return false;
    });

    $('.btn-lostpassword').click(function() {
        $('#login').addClass('is-hidden');
        $('#lostpassword').removeClass('is-hidden');
        return false;
    });

    $('.btn-login').click(function() {
        $('#login').removeClass('is-hidden');
    	$('#lostpassword').addClass('is-hidden');
    	return false;
    });
});